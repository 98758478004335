export default class GQL {
  constructor() {
    this.cache = {};

    import("@apollo/client").then(({ gql }) => {
      this.gql = gql;
    });
  }

  get(queryName) {
    // search for cache
    let query = this.cache[queryName];
    // if no cache
    if (query === undefined) {
      if (queries[queryName] === undefined) {
        return;
      }
      // generate the gql query
      query = this.gql(queries[queryName]);
      // cache it
      this.cache[queryName] = query;
    }
    // return the gql query
    return query;
  }
}

export const fragmentVideo = `
id
publisher
videoID
title
durationSeconds
description
embed
url
img
views
published
scoreTrending
channel {
  title
  url
  img
  description
}
papers {
  tags(where: {type:"task"}) {
    id
    name
  }
}
`;
const fragmentRepo = `
id
source
repoID
url
title
language
stars
forks
framework
owner {
  username
  avatar
}`;
//likes
//downloads

// const fragmentModel = `
// id
// displayName
// params
// #scoreSota
// __typename
// leaderboards {
//   id
//   displayName
//   modelsConnection(sort:{edge:{scorePosition:ASC}}) {
//     edges {
//       scorePerformance
//       scorePosition
//       node {
//         id
//         created
//         displayName
//         params
//         #scoreSota
//         paper {
//           publisher
//           paperID
//           published
//         }
//       }
//     }

//   }
// }
// `;

export const fragmentTask = `
id
name
description
scoreTrending
count {
  stars
  papers
  models
}
`;
export const fragmentPaper = `
id
paperID
publisher
title
authors
summary
updated
published
scoreTrending
summaries(options: {limit: 1}, where: { header: "paper.summary.expertise.beginner" }) {
  summary
}
tags(where: { type: "task" }) {
  id
  name
  __typename
}
reposAggregate(
  where: { OR: [{ NOT: { blackList: true } }, { blackList: null }] }
) {
  count
}
`;

const fragmentModel = `
  id
  org
  name
  displayName
  params
  created
  updated
  description
  meterSize
  scoreSota
  scoreTrending
  scorePerformance
  task
  tasks(where: { type: "huggingface-task" }) {
    ${fragmentTask}
  }
  repo {
    created
    updated
    likes
    description
    downloads
    scoreTrending
    hfOwner {
      avatar
    }
  }
  architecture {
    name
  }
`;
const paperQuery = `
id
publisher
updated
paperID
published
authors
title
scoreTrending
summary
lastCheckedForCode
links {
  id
  type
  url
  data
}
reposConnection(
  first: 100
  sort: { node: { stars: DESC, forks: DESC } }
  where: {
    OR: [
      { NOT: { node: { blackList: true } } }
      { node: { blackList: null } }
      { edge: { official: true } }
    ]
  }
  ) {
  edges {
    official
    node {
      ${fragmentRepo}
    }
  }
}
models(options: { sort: { params: DESC } }) {
  ${fragmentModel}
}
tags(where: { type: "task" }) {
  ${fragmentTask}
  __typename
}
summaries(options: { limit: 1, sort: { model: DESC } }, where: { header: "paper.summary.expertise.beginner", summary_NOT: null } ) {
  model
  header
  summary
}
emailsConnection(where: { node: { privacyOptOut: null } }) {
  edges {
    author
    node {
      id
      address
      name
      avatar
      linkedin
      bio
      site
      override
      membership {
        name
      }
      paper {
        modelsAggregate {
          count
        }
      }
      github {
        avatar
        username
      }
      scholar {
        thirdPartyID
      }
      twitter {
        avatar
        username
      }
      location {
        formatted
      }
      owner {
        id
        name
        github {
          avatar
        }
        email(options: { limit: 1 }, where: { avatar_NOT: null }) {
          avatar
        }
        authored(options: { sort: { pageRank: DESC } }) {
          id
          publisher
          paperID
        }
      }
    }
  }
}
`;

export const queries = {
  // trending
  trendingTasks: `
    query trendingTasks($limit: Int) {
      tags(
        where: { scoreTrending_GT: 0 }
        options: { sort: { scoreTrending: DESC } limit: $limit }
      ) {
        ${fragmentTask}
      }
    }`,
  trendingPapers: `
    query trendingPapers($limit: Int) {
      papers(
        where: { scoreTrending_GT: 0, article: true }
        options: { sort: { scoreTrending: DESC } limit: $limit }
      ) {
        ${fragmentPaper}
      }
    }
  `,
  // collections
  tasks: `
    query tasks {
      tags(where: { type: "task" }, options: { sort: { name: ASC } }) {
        id
        name
      }
    }
  `,
  tasksById: `
    query tasks($list: [String!]) {
      tags(where: { id_IN: $list }) {
        id
        name
      }
    }
  `,
  tasksWithCount: `
    query tasks {
      tags(where: { type: "task" }, options: { sort: { name: ASC } }) {
        id
        name
        count {
          models
        }
      }
    }
  `,
  // feed
  papersByTaskTrending: `
    query papersByTaskTrending($limit: Int, $task: String) {
      papers(
        where: { article: true, scoreTrending_GT: 0, optOut: null, tags_SOME: { name: $task} }
        options: { sort: { scoreTrending: DESC } limit: $limit }
      ) {
        ${fragmentPaper}
      }
   }
  `,
  papersByTaskNew: `
    query papersByTaskNew($limit: Int, $task: String) {
      papers(
        where: { article: true, optOut: null, tags_SOME: { name: $task} }
        options: { sort: { published: DESC } limit: $limit }
      ) {
        ${fragmentPaper}
      }
    }
  `,
  papersByTaskCount: `
    query papersByTaskCount($task: String) {
      papersAggregate(where: { tags_SINGLE: { name: $task } }) {
        count
      }
    }
  `,
  // get entity
  video: `
  query video( $id: String! ) {
    videos( where: { AND: [{id: $id}, {title_NOT: null}]} options: { limit: 1 } ) {
      ${fragmentVideo}
    }
  }
  `,
  repo: `
  query repo( $id: String! ) {
    repos( where: { id: $id } options: { limit: 1 } ) {
      ${fragmentRepo}
    }
  }
  `,
  task: `
  query task( $task: String! ) {
    tags( options: { limit: 1 } where: {name: $task, type:"task" }  ) {
      ${fragmentTask}
    }
  }
  `,
  paper: `
  query paper($id: String!) {
    papers(
      options: { limit: 1 }
      where: { id: $id, article: true, optOut: null }
    ) {
        ${paperQuery}
      }
    }
    `,
  paperMeta: `
  query paperMeta($id: String!) {
    papers(
      options: { limit: 1 }
      where: { id: $id, article: true, optOut: null }
    ) {
        id
        published
        title
        summary
        scoreTrending
        tags {
          name
        }
        summaries(options: { limit: 1 }, where: { header: "paper.summary.expertise.beginner" } ) {
          model
          header
          summary
        }
      }
    }
  `,
  /*
          models(where:{leaderboardsConnection_SOME:{edge:{scorePosition_LT:3}}},options:{sort:{scoreSota:DESC},limit:1}) {
         ${fragmentModel}
        }
  */
  // related to paper content
  references: `
    query references($id: String!) {
      papers(options: { limit: 1 }, where: { id: $id }) {
        referencesConnection(
          sort: { edge: { index: ASC } }
          where: { node: { NOT: { title: null }, optOut: null } }
        ) {
          edges {
            index
            node {
              url
              ${fragmentPaper}
            }
          }
        }
      }
    }
  `,
  similar: `
    query Similar($id: String!) {
      top_similar_papers(
        id: $id
      ) {
        id
        pageRank
        publisher
        paperID
        authors
        title
        updated
        scoreSimilarity
      }
    }
  `,
  alsoAuthoredBy: `
    query AlsoByAuthors($id: String!) {
      also_by_authors(id: $id) {
        id
        publisher
        paperID
        updated
        title
      }
    }
  `,
  repos: `
    query repos($id: String!) {
      papers(options: { limit: 1 }, where: { id: $id }) {
        reposConnection(
          first: 100
          sort: { node: { stars: DESC, forks: DESC } }
          where: {
            OR: [
              { NOT: { node: { blackList: true } } }
              { node: { blackList: null } }
              { edge: { official: true } }
            ]
          }
          ) {
          edges {
            official
            node {
              ${fragmentRepo}
            }
          }
        }
      }
    }
  `,
  repoOfficial: `
    query repoOfficial($id: String!) {
      papers(options: { limit: 1 }, where: { id: $id }) {
        reposConnection( where: { edge: { official: true } }) {
          edges {
            official
            node {
              ${fragmentRepo}
              summaries(where:{header:"readme.md"}) {
                summary
              }
            }
          }
        }
      }
    }
  `,
  citations: `
    query citations($id: String!) {
      papers(options: { limit: 1 }, where: { id: $id }) {
        citation(options: { sort:{ numberOfCitations: DESC }, limit: 100 }, where: { NOT: { title: null }, optOut: null }) {
          ${fragmentPaper}
        }
      }
    }
`,
  author: `
    query author($id: String!) {
      emails(
        where: {
          AND: [
            { privacyOptOut: null }
            { OR: [{ id: $id },{ owner: { id:$id }}] }
          ]
        }
        options: { limit: 1 }
      ) {
        id
        name
        avatar
        linkedin
        bio
        site
        override
        paperConnection {
          edges {
            author
            node {
              id
              tags(where: { type: "task" }) {
                name
              }
            }
          }
        }
        github {
          avatar
          username
        }
        scholar {
          thirdPartyID
        }
        twitter {
          avatar
          username
        }
        location {
          formatted
        }
        owner {
          id
          name
          github {
            avatar
          }
          authored(options: { sort: { pageRank: DESC } }) {
            id
            tags(where: { type: "task" }) {
              name
            }
          }
          email(where: { id_NOT: $id }) {
            name
            avatar
            linkedin
            bio
            site
            github {
              avatar
              username
            }
            scholar {
              thirdPartyID
            }
            twitter {
              avatar
              username
            }
            location {
              formatted
            }
          }
        }
      }
    }
  `,
  model: `
    query model($name: String!) {
      models(options: { limit: 1 }, where: { name: $name } ) {
        ${fragmentModel}
      }
    }
  `,
  modelSameOrg: `
    query modelByOrg($limit: Int!, $org:String!, $modelList:[String!]) {
      repos(
        where:{
          source: "huggingface",
          repoID_STARTS_WITH: $org,
          models_NONE: { id_IN: $modelList }
        }
        options: { limit: $limit sort:{ likes: DESC, created: DESC } }
      ) {
        models(options: { limit: 1 }) {
          ${fragmentModel}
        }
      }
    }
  `,
  modelSameName: `
    query modelByOrg($limit: Int!, $modelNames:[String!], $modelList:[String!]) {
      repos(
        where:{
          source: "huggingface",
          models_SINGLE: { displayName_IN: $modelNames }
          models_NONE: { id_IN: $modelList }
          OR: [ { likes_GT: 0 }, { downloads_GT: 0 } ]
        }
        options: { limit: $limit, sort:{ likes: DESC, created: DESC } }
      ) {
        models(options: { limit: 1 }) {
          ${fragmentModel}
        }
      }
    }
  `,
  sameArchitecture: `
    query modelByArchitecture($limit: Int!, $modelList: [String!], $architectures: [String!] ) {
      repos(
        where:{
          source: "huggingface",
          models_SOME: { architecture_SOME: { name_IN: $architectures } }
          models_NONE: { id_IN: $modelList }
          likes_GT: 0
        }
        options: { limit: $limit, sort:{ likes: DESC, created: DESC } }
      ) {
        models(options: { limit: 1 }) {
          ${fragmentModel}
        }
      }
    }
  `,
  modelsByTask: `
    query modelsByTask {
      tags(
        where: { type: "huggingface-task" }
        options: { sort: { name: ASC }, limit:5 }
      ) {
        name
        models(options: { sort: { minRequiredCpuRamGB: ASC }, limit: 100 } ) {
          ${fragmentModel}
        }
      }
    }
  `,
  // Search
  searchPapers: `
    query searchPapersMatch(
      $query: String
      $time: String
      $tag_list: [String]
      $publisher: String
      $limit: Int = 0
      $sortBy: String
    ) {
      search_papers_2_match(
        query: $query
        time: $time
        tag_list: $tag_list
        publisher: $publisher
        limit: $limit
        sortBy: $sortBy
      ) {
        ${fragmentPaper}
      }
    }`,
  searchPapers2_vector: `
    query searchPapersVector(
      $vector: [Float]
      $time: String
      $tag_list: [String]
      $publisher: String
      $limit: Int = 0
      $sortBy: String
    ) {
      search_papers_2_vector(
        vector: $vector
        time: $time
        tag_list: $tag_list
        publisher: $publisher
        limit: $limit
        sortBy: $sortBy
      ) {
        ${fragmentPaper}
      }
    }`,
  searchPapers2_no_vector: `
    query searchPapersNoVector(
      $time: String
      $tag_list: [String]
      $publisher: String
      $limit: Int = 0
      $sortBy: String
    ) {
      search_papers_2_no_vector(
        time: $time
        tag_list: $tag_list
        publisher: $publisher
        limit: $limit
        sortBy: $sortBy
      ) {
        ${fragmentPaper}
      }
    }`,
  searchPapersCount: `
    query searchPapersCount($query:String, $tag_list:[String], $time: String, $publisher: String) {
      search_papers_count(query: $query, tag_list: $tag_list, publisher: $publisher, time: $time) {
        count
      }
    }`,
  searchVideos: `
    query searchVideos(
      $query: String
      $tag_list: [String]
      $time: String
      $limit: Int
      $sortBy: String
      ) {
      search_videos(
        query: $query
        tag_list: $tag_list
        time: $time
        limit: $limit
        sortBy: $sortBy
      ) {
        ${fragmentVideo}
      }
    }
  `,
  searchVideosCount: `
    query searchVideosCount($query:String, $tag_list:[String], $time: String) {
      search_videos_count(query: $query, tag_list: $tag_list, time: $time) {
        count
      }
    }`,
  searchTasks: `
    query searchTasks( $limit: Int = 0, $query: String, $sortBy: String) {
      search_tasks(limit: $limit, query: $query, sortBy: $sortBy) {
        ${fragmentTask}
      }
    }`,
  searchTasksCount: `
    query searchTasksCount($query: String) {
      search_tasks_count(query: $query) {
        count
      }
    }`,
  searchModels: `
    query searchModelsQuery(
      $query: String
      $sortBy: String
      $tag_list: [String]
      $limit: Int
      $skip: Int
    ) {
      search_models(
        query: $query
        sortBy: $sortBy
        tag_list: $tag_list
        limit: $limit
        skip: $skip
      ) {
        ${fragmentModel}
      }
    }`,
  searchModelsNoQuery: `
    query searchModelsNoQuery(
      $tag_list: [String]
      $sortBy: String
      $limit: Int
      $skip: Int
    ) {
      search_models_no_query(
        sortBy: $sortBy
        tag_list: $tag_list
        limit: $limit
        skip: $skip
      ) {
        ${fragmentModel}
      }
    }`,
  searchModelsRepos: `
    query searchModelsRepos(
      $tag_list: [String]
      $sortBy: String
      $limit: Int
      $skip: Int
    ) {
      search_models_repos(
        tag_list: $tag_list
        sortBy: $sortBy
        limit: $limit
        skip: $skip
      ) {
        ${fragmentRepo}
        models {
          ${fragmentModel}
        }
      }
    }`,
  searchModelsCount: `
    query searchModelsCount($query: String $tag_list: [String]) {
      search_models_count2( query: $query tag_list: $tag_list) {
        count
      }
    }`,
  conference: `
    query conference($paperNodeIds:[String!]) {
      papers(
        where: { id_IN: $paperNodeIds }
      ) {
        ${fragmentPaper}
      }
    }
  `,
  // other
  huggingfaceTasks: `
    query huggingfaceTasks {
      tags(
        where: {
          type: "huggingface-task"
          repos_SOME: {
            AND: [{ source: "huggingface" }, { modelsAggregate: { count_GT: 0 } }]
          }
        }
        options: { sort: { name: ASC } }
      ) {
        id
        name
        modelsAggregate {
          count
        }
      }
    }
  `,
  trendingModels: `
    query trendingPapers($limit: Int) {
      repos(
        where: {AND:[{ scoreTrending_GT: 0 }, {modelsAggregate:{count_GT:0}}]}
        options: { sort: { scoreTrending: DESC } limit: $limit }
      ) {
        models {
          ${fragmentModel}
        }
      }
    }
  `,
  topModelsByTask: `
    query topModelsByTask {
      top_models_by_task {
        task
        list
      }
    }
  `,
  modelsById: `
    query modelsById( $modelIds: [String!], $limit: Int) {
      models( where: { id_IN: $modelIds }, options: { limit: $limit } ) {
        ${fragmentModel}
      }
    }
  `,
  modelsByName: `
    query modelsByName( $list: [String!], $limit: Int) {
      models( where: { name_IN: $list }, options: { limit: $limit } ) {
        ${fragmentModel}
      }
    }
  `,
  modelsByRepoId: `
    query modelsByRepoId($limit: Int!, $ids:[String!]) {
      repos(where:{ id_IN: $ids } options: { limit: $limit }) {
        repoID
        updated
        created
        downloads
        likes
        models(options:{ limit: 1 }) {
          ${fragmentModel}
        }
      }
    }
  `,
  // feed
  feedNewPapers: `
    query feedNewPapers($limit: Int, $task: String) {
      papers(
        where: { article: true, NOT: {title:null}, optOut: null, tags_SOME: { name: $task} }
        options: { sort: { published: DESC } limit: $limit }
      ) {
        article
        ${fragmentPaper}
      }
    }
  `
  // feedNewModels: `
  //   repos(
  //       where: { source: "huggingface", tags_SOME: { name: $task } }
  //       options: { limit: $limit, sort: { created: DESC } }
  //     ) {
  //       ${fragmentRepo}
  //       models {
  //         ${fragmentModel}
  //       }
  //     }
  //   }
  // `
};
